import {chakra} from '@chakra-ui/react'

const Svg = chakra('svg')

export const FairLevelSvg = (props: React.ComponentProps<typeof Svg>) => (
  <Svg
    width='22'
    height='16'
    viewBox='0 0 22 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}>
    <g clipPath='url(#clip0_4619_1474)'>
      <path
        d='M19.9295 15.8828C20.3113 16.0935 20.7952 15.9572 20.9789 15.5628C21.6693 14.0807 22.0193 12.4603 21.9991 10.82C21.9758 8.91681 21.4548 7.05251 20.4877 5.41083C19.5204 3.76916 18.1404 2.40679 16.4833 1.45799C14.8264 0.509194 12.9497 0.0067109 11.0384 6.67562e-05C9.12703 -0.00657739 7.24692 0.482847 5.58334 1.4201C3.91977 2.35735 2.53015 3.7101 1.55145 5.34501C0.572736 6.97991 0.0387216 8.84055 0.00202766 10.7436C-0.0295976 12.3838 0.309135 14.0065 0.989083 15.4934C1.16998 15.889 1.65287 16.0287 2.0361 15.8206C2.41933 15.6126 2.5573 15.1356 2.38122 14.7378C1.83 13.4929 1.55608 12.1404 1.58243 10.7738C1.61386 9.14423 2.07114 7.55097 2.9092 6.15099C3.74727 4.75103 4.9372 3.59268 6.36172 2.7901C7.78623 1.98753 9.39618 1.56843 11.0329 1.57413C12.6695 1.57982 14.2765 2.01009 15.6954 2.82255C17.1143 3.635 18.2961 4.8016 19.1243 6.20736C19.9525 7.61312 20.3986 9.20953 20.4186 10.8392C20.4354 12.206 20.152 13.5566 19.5921 14.7977C19.4131 15.1942 19.5477 15.6721 19.9295 15.8828Z'
        fill='#00B880'
      />
      <path
        d='M15.2673 6.10131C15.5596 5.84258 16.0005 5.844 16.2912 6.1046C16.584 6.36714 16.6297 6.80778 16.397 7.1244L12.6473 12.2261C12.1421 12.9135 11.1508 13.0156 10.5151 12.4455C9.87503 11.8716 9.87826 10.8711 10.522 10.3013L15.2673 6.10131Z'
        fill='#00B880'
      />
      <path
        d='M11.0617 13.7841C12.1198 13.7841 12.9775 12.93 12.9775 11.8764C12.9775 10.8229 12.1198 9.96875 11.0617 9.96875C10.0037 9.96875 9.146 10.8229 9.146 11.8764C9.146 12.93 10.0037 13.7841 11.0617 13.7841Z'
        fill='#00B880'
      />
    </g>
    <defs>
      <clipPath id='clip0_4619_1474'>
        <rect width='22' height='16' fill='white' />
      </clipPath>
    </defs>
  </Svg>
)